import React, { useState, useRef, useEffect, FC, lazy, Suspense } from "react";
const LoginPage = lazy(() => import("./components/loginPage"));
const HomePage = lazy(() => import("./components/homePage"));
import LoadingAnimation from "./components/loginComponents/loadingAnimation";

import LoginMapArt from "@root/static/img/mapart/i-pc.webp";

var ScrapCity: FC = () => {
  const [userAuthorised, setUserAuthorised] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addAccountOption, setAddAccountOption] = useState<string | null>(null);
  const [userKeys, setUserKeys] = useState<null | {
    token: string;
    uidb64: string;
  }>(null);
  const [APIKey, setAPIKey] = useState<null | string>(null);
  const vapidPublicKey = useRef('')
  const [taskID, setTaskID] = useState<null | string>(null);

  const isAuthorised = (): void => {
    fetch("/api/isloggedin/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Capacitor-App": window.isNativeApp!.toString()
      },
    })
      .then((response) => {
        if (response.ok || response.status !== 401) {
          return response.json();
        } else {
          throw new Error(
            "Connection error when checking authentication.\nAuthentication required"
          );
        }
      })
      .then((data) => {
        const retrievedData = data;
        if (retrievedData.detail == "You are authenticated!") {
          setAPIKey(retrievedData.key);
          setUserAuthorised(true);
          vapidPublicKey.current = retrievedData.vapid_key
        } else {
          console.error("User not authorised, redirecting to login page");
          setAddAccountOption(retrievedData.addAccountOption);
          setUserAuthorised(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const checkIfPasswordReset = (): boolean => {
    const pathname: string = window.location.pathname;
    if (pathname.includes("/confirm-Password-Reset/")) {
      return true;
    }
    return false;
  };

  const parseUrlKeys = (): { uidb64: string; token: string } => {
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    const uidb64 = parts[2];
    const token = parts[3];
    return { uidb64: uidb64, token: token };
  };

  const chooseView = () => {
    if (!isLoading) {
      return userAuthorised ? (
        <Suspense fallback={<LoadingAnimation addCenter={true} />}>
          <HomePage
            taskID={taskID}
            APIKey={APIKey!}
            userKeys={userKeys}
            vapidKey={vapidPublicKey.current}
            setUserAuthorised={setUserAuthorised}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<LoadingAnimation addCenter={true} />}>
          <LoginPage
            setAPIKey={setAPIKey}
            setUserAuthorised={setUserAuthorised}
            setTaskID={setTaskID}
            addAccountOption={addAccountOption}
          />
        </Suspense>
      );
    } else {
      return <LoadingAnimation addCenter={true} />;
    }
  };

  useEffect(() => {
    if (isLoading) {
      var timer = setTimeout(() => {
        setIsLoading(false); // Update the state here
      }, 2000);
      // 10000 milliseconds = 10 seconds
    }
    return () => {
      if (timer != undefined) {
        clearTimeout(timer); // Clear the timer if the component is unmounted
      }
    };
  }, [userAuthorised, APIKey]);

  useEffect(() => {
    setIsLoading(true);
    if (!checkIfPasswordReset()) {
      isAuthorised();
    } else {
      const newUserKeys = parseUrlKeys();
      setUserKeys(newUserKeys);
      setUserAuthorised(true); //Fake login. No real access to data.
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <div
        id="appContainer"
        className="noSelect"
        style={{
          backgroundImage: `url(${LoginMapArt})`,
        }}
      >
        {chooseView()}
      </div>
    </>
  );
};
export default ScrapCity;
