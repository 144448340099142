import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { Capacitor } from '@capacitor/core';
import ScrapCity from './ScrapCity';
import { setupBackButtonHandler } from './components/back';

const root = createRoot(document.getElementById('app')!);  

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
       navigator.serviceWorker.register('/sw.js').then(function(registration) {
         // Registration was successful
         console.log('ServiceWorker registration successful with scope: ', registration.scope); 
       }, function(err) {
         // registration failed :(
         console.log('ServiceWorker registration failed: ', err); 
       });
    });
   };

if (Capacitor.isNativePlatform()) { 
    // We're running in Capacitor 
    window.isNativeApp = true; 
    setupBackButtonHandler();
}else {
    window.isNativeApp = false; 
}

root.render(<ScrapCity />);
