// In src/navigation/backButtonHandler.ts
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

type BackFunction = () => void;
const backFunctionStack: BackFunction[] = [];

export function pushBackFunction(fn: BackFunction) {
  backFunctionStack.push(fn);
}

export function popBackFunction(): BackFunction | undefined {
  return backFunctionStack.pop();
}

export function getCurrentBackFunction(): BackFunction | undefined {
  return backFunctionStack[backFunctionStack.length - 1];
}

export function setupBackButtonHandler() {
    App.addListener('backButton', () => {
      const backFn = getCurrentBackFunction();
      if (backFn) {
        backFn();
        return true; // Prevent default back button behavior
      } else {
        // If no back function is available, show exit confirmation
        if (confirm('Do you want to exit the app?')) {
          App.exitApp();
        }
        return true; // Prevent default back button behavior
      }
    });
}
